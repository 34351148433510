import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;
  
  .box {
    justify-content: center;
    width: 800px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 999px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  div {
    margin: 1.25rem 0rem;
    padding: 0rem 1rem 0rem 1rem;
    border-radius: 0.25rem;
    color: var(--text-title);
    @media (max-width: 999px) {
      padding: .5rem 1rem;
    }

    header {
      align-items: center;
      text-align: center;
      text-decoration: none;
      padding: 1rem 0;

      &:hover{
        background-color: #ADD8E6;
      }

      div {
        margin: auto;
        display: flex;
        justify-content: center;
        color: black;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 100px;
        height: 100px;
        margin-bottom: 14px;

        img {
          align-self: center;
          width: 3rem;
          height: 3rem;
        }
      }
      
      @media (max-width: 1000px) {
        div {
        width: 89px;
        height: 89px;
        margin-top: 0;
        margin-bottom: 0;

        img {  
          align-self: center;
          width: 3.5rem;
          height: 3.5rem;
        }
      }
      }

      p {
        padding-top: 2rem;
        display: block;
        padding-bottom: .9rem;
        font-weight: 600;
        font-size: 1.3rem;
        color: black;

        @media (max-width: 500px) {
          font-size: 14px;
        }
      }
      span {
        color: black;
      }
    }
  }    
`
