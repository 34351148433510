import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  background-color: #69ABF2;
;

    header {
      padding-top: 3rem;
      padding-bottom: 2rem;
      margin: 0 1rem;
      align-items: center;
      text-align: center;
      font-size: 3rem;

      @media (max-width: 720px) {
        font-size: 2rem;
        margin: 0 .5rem;
      }
      p {
        display: block;
        font-weight: 600;
        color: #000;
      }
    }

    .subContainer {
      margin:auto;
      align-items: center;
      text-align: center;
      justify-content: center;
      align-content: center;
      
      .mainText {
        padding-bottom: 3rem;
        margin: 0 15rem;
        @media (max-width: 1200px) {
          margin: 0 5rem;
        }
      
      p {
        font-size: 1.3rem;
        text-align: center;
        color: #000;
        margin: 0rem 0rem;
        padding-bottom: 2rem;

        @media (max-width: 600px) {
          font-size: 1.2rem;
        }
      }
      span {
        justify-content: center;
        text-align: center;
        display: block;
        color: #000;
        font-weight: 800;
        font-size: 1.2rem;
        margin: 0rem 0rem;
        @media (max-width: 600px) {
          font-size: 1.1rem;
        }

        &:last-child{
          padding-top: .5rem;
          font-size: 1.2rem;
          @media (max-width: 600px) {
          font-size: 1.2rem;
        }
        }
      }

    }
    div {
      text-align: center;
      padding-bottom: 3rem;
      
      button {
        border: none;
        background-color: #fff;
        padding: 0.75rem 2.5rem;
        border-radius: 6px;
        &:hover{
          background-color: #E0FFFF;
        }

        p {
          font-size: 1.25rem;
          font-weight: bolder;
          color: black;
          @media (max-width: 720px) {
            font-size: 1.1rem;
          }
        }
    }
  }
}
`
