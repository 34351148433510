import { useState } from 'react';
import { Header, ServicosSection } from "./styleServicos";

import ReactGA from 'react-ga';

import oneImg from '../../Assets/GaleriaRevestimento/2.jpg'
import twoImg from '../../Assets/GaleriaImpExterna/1.jpg'
import threeImg from '../../Assets/GaleriaRejeitos/1.jpg'
import fourImg from '../../Assets/GaleriaAguaFluvial/1.jpg'
import fifthImg from '../../Assets/GaleriaTampas/1.jpg'
import sixthImg from '../../Assets/GaleriaServicosExecutados/1.jpg'

import { RevestimentoModalFotos } from '../../Components/ModalPhotos/ServicosFotosRevestimento';
import { TampasModalFotos } from '../../Components/ModalPhotos/ServicosFotosTampas';
import { ImpExternaModalFotos } from '../../Components/ModalPhotos/ServicosFotosImpExterna';
import { LagoasRejeitosModalFotos } from '../../Components/ModalPhotos/ServicosFotosLagoasRejeitos';
import { LagoasAguaFluvialModalFotos } from '../../Components/ModalPhotos/ServicosFotosLagoasAguaFluvial';
import { ServicosExecutadosModalFotos } from '../../Components/ModalPhotos/ServicosFotosExecutados';

function initizeAnalytics() {
  ReactGA.initialize("UA-191935382-1");
  ReactGA.pageview('/servicos');
}

export function Servicos() {
  initizeAnalytics();
  const [revestimentoModalFotos, setRevestimentoModalFotos] = useState(false);
  const [impExternaModalFotos, setImpExternaModalFotos] = useState(false);
  const [tampasModalFotos, setTampasModalFotos] = useState(false);
  const [lagoasRejeitosModalFotos, setLagoasRejeitosModalFotos] = useState(false);
  const [lagoasAguaFluvialModalFotos, setLagoasAguaFluvialModalFotos] = useState(false);
  const [servicosExecutadosModalFotos, setServicosExecutadosModalFotos] = useState(false);
  
  function revestimentoModalOpen() {
    setRevestimentoModalFotos(true);
  }
  
  function impExternaModalOpen() {
    setImpExternaModalFotos(true);       
  }

  function tampasModalOpen() {
    setTampasModalFotos(true);       
  }
  
  function lagoasRejeitosModalOpen() {
    setLagoasRejeitosModalFotos(true);       
  }

  function lagoasAguaFluvialModalOpen() {
    setLagoasAguaFluvialModalFotos(true);       
  }
  
  function servicosExecutadosModalOpen() {
    setServicosExecutadosModalFotos(true);       
  }



  function handleCloseRevestimentoModal() {
    setRevestimentoModalFotos(false);     
  }

  function handleCloseImpExternaModal() {
    setImpExternaModalFotos(false);     
  }

  function handleCloseTampasModal() {
    setTampasModalFotos(false);     
  }
  
  function handleCloseLagoasRejeitosModal() {
    setLagoasRejeitosModalFotos(false);     
  }
  
  function handleCloseLagoasAguaFluvialModal() {
    setLagoasAguaFluvialModalFotos(false);     
  }
  
  function handleCloseServicosExecutadosModal() {
    setServicosExecutadosModalFotos(false);     
  }

  return (
    <>
      <Header>
        <div className="header">
          <h1>Serviços</h1>
        </div>
      </Header>

      <ServicosSection>
      <div className="headerTitle">
        <h2>Serviços Executados</h2>
      </div>
      <div className="contentServicos">
        <div className="contentItems">
          
          <div onClick={revestimentoModalOpen}>
            <header>
                <div>
                  <img src={oneImg} alt="Revestimento" />
                <p>Revestimento</p>
                </div>
            </header>
          </div> 
          <RevestimentoModalFotos 
            isOpen={revestimentoModalFotos}                
            onRequestClose={handleCloseRevestimentoModal}
          />     
          
          <div onClick={impExternaModalOpen}>
            <header>
                <div>
                  <img src={twoImg} alt="Impermeabilização Externa" />
                <p>Impermeabilização Externa</p>
                </div>
            </header>
          </div> 
          <ImpExternaModalFotos 
            isOpen={impExternaModalFotos}                
            onRequestClose={handleCloseImpExternaModal}
          />     
          
          <div onClick={lagoasRejeitosModalOpen}>
            <header>
                <div>
                  <img src={threeImg} alt="Biodigestor" />
                <p>Biodigestor</p>
                </div>
            </header>
          </div>   
          <LagoasRejeitosModalFotos
                isOpen={lagoasRejeitosModalFotos}                
                onRequestClose={handleCloseLagoasRejeitosModal}
              />
          
          <div onClick={lagoasAguaFluvialModalOpen}>
            <header>
                <div>
                  <img src={fourImg} alt="Lagoas" />
                <p>Lagoas</p>
                </div>
            </header>
          </div>   
          <LagoasAguaFluvialModalFotos
                isOpen={lagoasAguaFluvialModalFotos}                
                onRequestClose={handleCloseLagoasAguaFluvialModal}
          />
          
          <div onClick={tampasModalOpen}>
            <header>
                <div>
                  <img src={fifthImg} alt="Tampas" />
                <p>Tampas</p>
                </div>
            </header>
          </div> 
            <TampasModalFotos
                isOpen={tampasModalFotos}                
                onRequestClose={handleCloseTampasModal}
            />
          

          <div onClick={servicosExecutadosModalOpen}>
            <header>
                <div>
                  <img src={sixthImg} alt="Serviços Executados" />
                <p>Serviços Executados</p>
                </div>
            </header>
          </div>   
          <ServicosExecutadosModalFotos
                isOpen={servicosExecutadosModalFotos}                
                onRequestClose={handleCloseServicosExecutadosModal}
          />
          
          
        </div>
      </div>
      </ServicosSection>
    
    </>
  );
}
