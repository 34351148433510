import { Link } from 'react-router-dom';
import { Navbar } from "../Navbar";
import logoImg from '../../Assets/logoCaixadagua.png'
import { Container, Content, Menu } from './styles'

export function Header() {
  return (
    <Container >
      <Navbar />
      <Content>
        <Link to="/">
          <img src={logoImg} alt="Caixa D'Água Revestimentos" />
          {/* <p>Caixa D'Água Revestimentos</p> */}
        </Link>
      </Content>

      <Menu>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/quem-somos">Quem Somos</Link>
          </li>
          <li>
            <Link to="/revestimentos">Revestimento</Link>
          </li>
          <li>
            <Link to="/servicos">Serviços</Link>
          </li>
          <li>
            <Link to="/fale-conosco">Fale Conosco</Link>
          </li>
        </ul>
      </Menu>

    </Container>
  )
}