import { useAnalyticsEventTracker } from "../../Utils/eventTracker"
import WhatsappRS from "../../Assets/WhatsRS.png";
import WhatsappSC from "../../Assets/WhatsSC.png";
import Instagram from "../../Assets/Instagram_new.svg";
import Facebook from "../../Assets/Facebook.svg";
import { Container, Whats, WhatsSC } from './styles'

export function Footer() {
  const gaEventTrackerWhatsapp = useAnalyticsEventTracker('Whatsapp');
  const gaEventTrackerSocialMedias = useAnalyticsEventTracker('Social Media');


  return (
    <Container >
      <Whats>
        <div>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5551992145030&text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento."
            title="Whatsapp"
            rel="noreferrer"
            onClick={() => gaEventTrackerWhatsapp('Whatsapp RS')}
          >
            <img src={WhatsappRS} alt="Whatsapp" className="whatsImg" />


          </a>
        </div>
      </Whats>
      <WhatsSC>
        <div>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5548999941432&text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento."
            title="WhatsappSC"
            rel="noreferrer"
            onClick={() => gaEventTrackerWhatsapp('Whatsapp SC')}
          >
            <img src={WhatsappSC} alt="Whatsapp" className="whatsImg" />

          </a>
        </div>
      </WhatsSC>

      <header>
        <p>Visite nossas redes sociais:</p>
      </header>
      <div className="footerContainer">

        <div className="socialMediaFooter">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/caixadaguarevestimentos"
                target="_blank"
                title="Visite nosso facebook"
                rel="noreferrer"
                onClick={() => gaEventTrackerSocialMedias('Facebook')}
              >
                <img src={Facebook} alt="Facebook" className="mediaImgFooter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/caixadaguarevestimentos"
                target="_blank"
                title="Visite nosso Instagram"
                rel="noreferrer"
                onClick={() => gaEventTrackerSocialMedias('Instagram')}
              >
                <img src={Instagram} alt="Instagram" className="mediaImgFooter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footerContact">
        <span>Rio Grande do Sul</span>
        <span>Santa Catarina</span>
      </div>
      <div className="copyright">
        <p>© 2021 Caixa D'Água Revestimentos</p>
      </div>

    </Container>
  )
}