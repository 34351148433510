import backgroundHeader from '../../Assets/backgroundHeader.jpg'
import styled from 'styled-components';

export const Header = styled.header`
  padding-top: 7rem;

  .header {
    background-image: url(${backgroundHeader});
    height: 8rem;
    margin:auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;

    h1 {
      color: white;
    }
  }
`
export const ServicosSection = styled.div`
  max-width: auto;
  text-align: center;

  .headerTitle {
    margin: 0;
    
    h2 {
      color: black;    
      font-size: 2rem;
      padding-top: 1.5rem;
    }
}

  .contentServicos {
    margin: 0 2rem;
    margin-bottom: 4rem;
    max-width: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;
  }

  .contentItems {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 20rem);
    justify-content: center;
    align-items: center;


    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  div {
    gap: 3rem;  
    margin: 0rem 2rem;
    padding: 1rem 1rem;
    color: var(--text-title);
    
    header {
      align-items: center;
      text-align: center;
      
      div {
        width: 16rem;
        height: 18rem;
        background-color: rgba(173, 216, 230, 0.4);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        cursor: pointer;
        margin: 0rem 0rem;
        padding-top: 2.5rem;

        @media (max-width: 600px) {
          width: 14rem;
          height: 18rem;
          padding-top: 2.5rem;
        }
        @media (max-width: 440px) {
          padding-top: 1.5rem;
        }
        
        @media (max-width: 440px) {
          width: 12rem;
          height: 12rem;
        }

        img {
          flex-shrink: 0;
          width: 10rem;
          height: 8rem;
          
          @media (max-width: 440px) {
            width: 8rem;
            height: 5rem;
          }
        }
      }
      
      p {
        padding-top: 1rem;
        display: block;
        font-weight: 600;
        font-size: 1.2rem;
        color: black;

        @media (max-width: 600px) {
          font-size: 1.2rem;
          padding-top: .9rem;
        }

        @media (max-width: 440px) {
          font-size: 1rem;
          padding-top: 1rem;
        }
      }
    }

    @media (max-width: 700px) {
      gap: 0rem;  
      margin: 0rem 0rem;
    }
  }    
`