import emailjs from "emailjs-com";
import { Header, Container } from "./styleContatos";
import { useAnalyticsEventTracker } from "../../Utils/eventTracker"


export default function SendEmail() {
  const gaEventTrackerEmail = useAnalyticsEventTracker('Email');

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(
      'service_xu9bo9p',
      'template_inu2wpm',
      e.target,
      'user_t2k2hDgrEpj4B3yx9tvAR'
    )

      .then((result) => {
        gaEventTrackerEmail('Email')
        alert("Mensagem enviada com sucesso! 👍");
      }, (error) => {
        alert(error.message)

      });
    e.target.reset()


  }

  return (
    <>
      <Header>
        <div className="header">
          <h1>Fale Conosco</h1>
        </div>
      </Header>
      <Container>
        <form onSubmit={sendEmail}>
          <div className="containerForm">
            <h1>Nome</h1>
            <input type="text" autoFocus required placeholder="Informe seu nome" name="name" maxlength="40" />
            <h1>E-mail</h1>
            <input type="email" required placeholder="Informe seu e-mail" name="email" maxlength="50" />
            <h1>Telefone</h1>
            <input type="tel" required placeholder="Informe seu telefone" name="fone" maxlength="14" />
            <h1>Mensagem</h1>
            <textarea id="" cols="30" rows="8" required placeholder="Por favor, informe sua cidade, bairro e a solicitação que deseja." name="message" maxlength="350"></textarea>


            <button
              type="submit"
              className="button-enviar"
              value="Enviar mensagem"
            >
              Enviar
            </button>

          </div>

        </form>

      </Container >
    </>
  )
}