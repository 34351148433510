import ReactGA from 'react-ga';
import { Banner } from "../Components/Carousel";
import { SectionA } from "../Components/SectionA";
import { SectionB } from "../Components/SectionB";

function initizeAnalytics() {
  ReactGA.initialize("UA-191935382-1");
  ReactGA.pageview('/home');
}

export function Home() {
  initizeAnalytics()
  return (
    <>
        <Banner />
        <SectionA />
        <SectionB />
    </>
  );
}
