import { Link } from 'react-router-dom';

import { Container } from './styles';

// import oneImg from '../../Assets/champion.svg'
import twoImg from '../../Assets/Group.svg'

import threeImg from '../../Assets/services.svg'
// import * as FaIcons from "react-icons/fa";
// import { FaBeer } from 'react-icons/fa';


export function SectionA() {
  return (
    <Container>
      <div className="box">
        {/* <div>
          <header>
            <Link to="/revestimentos">
              <div>
                <img src={oneImg} alt="Caixa D'Água Revestimentos" />
              </div>
              <p>Tecnologia Exclusiva</p>
              <span>Material de alta qualidade</span>
            </Link>
          </header>
        </div>
        <div>
          <header>
            <Link to="/revestimentos">
              <div>
                <img src={twoImg} alt="Caixa D'Água Revestimentos" />
              </div>
              <p>Sistema de Revestimento</p>
              <span>PVC Flexível e Atóxico</span>
            </Link>
          </header>
        </div> */}
        <div>
          <header>
            <Link to="/revestimentos">
              <div>
                <img src={twoImg} alt="Caixa D'Água Revestimentos" />
              </div>
              <p>Vantagens</p>
              <span>Instalação em 1 dia</span>
            </Link>
          </header>
        </div>
        <div>
          <header>
            <Link to="/servicos">
              <div>
                <img src={threeImg} alt="Caixa D'Água Revestimentos" />
              </div>
              <p>Serviços Oferecidos</p>
              <span>Profissionais treinados e capacitados para executar serviços especializados</span>
            </Link>
          </header>
        </div>
      </div>
    </Container>

  );
}