import ImageGallery from 'react-image-gallery';
import foto1 from "../../Assets/GaleriaTampas/1.jpg";
import foto2 from "../../Assets/GaleriaTampas/2.jpg";
import foto3 from "../../Assets/GaleriaTampas/3.jpg";
import foto4 from "../../Assets/GaleriaTampas/4.jpg";
import foto5 from "../../Assets/GaleriaTampas/5.jpg";
import foto6 from "../../Assets/GaleriaTampas/6.jpg";
import foto7 from "../../Assets/GaleriaTampas/7.jpg";
import foto8 from "../../Assets/GaleriaTampas/8.jpg";

import { Container } from "./styleGaleriaServicos";

export function TampasCarousel({ fotosTampas }: any) {
  const images = [
    {
      original: foto1,
      // thumbnail: foto1Thumbs,
    },
    {
      original: foto2,
    },
    {
      original: foto3,
    },
    {
      original: foto4,
    },
    {
      original: foto5,
    },
    {
      original: foto6,
    },
    {
      original: foto7,
    },
    {
      original: foto8,
    },
  
  ];
  

  fotosTampas = images;

  return (
    
     <Container>
      <ImageGallery items={fotosTampas} />
      </Container>
  )};

