import { Link } from 'react-router-dom';
import { Container } from './styles'

export function SectionB() {
  return (
    <Container>
      <header>
        <p>Quem Somos</p>
      </header>
      <div className="subContainer">
        <div className="mainText">
          <p>Empresa especializada em manutenção de Caixas D’Água!
            Tecnologia exclusiva contra infiltrações e forte deterioração da impermeabilização interna.
          </p>
          <span>Já pensou qual a importância da água que você consome?</span>
          <span>Então não deixe para depois o que você consome hoje!!!</span>
        </div>
        <div>
          <button>
            <Link to="/quem-somos"><p>Saiba mais</p></Link>
          </button>
        </div>
      </div>
    </Container>
  );
}