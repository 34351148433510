import { Route, Switch } from "react-router-dom";
import { Home } from "./pages";
import { FaleConosco } from "./pages/FaleConosco";
// import { Contatos } from "./pages/Contatos";
import { QuemSomos } from "./pages/QuemSomos";
import { Revestimentos } from "./pages/Revestimentos";
import { Servicos } from "./pages/Servicos";

export function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/fale-conosco" component={FaleConosco} />
      {/* <Route path="/contatos" component={Contatos} /> */}
      <Route path="/quem-somos" component={QuemSomos} />
      <Route path="/revestimentos" component={Revestimentos} />
      <Route path="/servicos" component={Servicos} />
    </Switch>
  );
}
