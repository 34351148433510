import styled from 'styled-components';


export const Container = styled.div`

  .containerCarousel {
      img {
        object-fit: cover;
        
        height: 600px;
      }
    }

  
  div {
    padding-top: 1.4rem;
    
  }

  .carousel .thumb {
    display: none;
  }
  .carousel .thumbs-wrapper {
    display: none;
  }

  .carouesl.carouesl-slider.control-arrow {
    padding-top: 0px;
  }

  .control-arrow {
    display: none;
  }

  .carousel .control-dots .dot {
    width: 16px;
    height: 16px;
    background-color: #000;
    
    @media (max-width: 450px) {
      width: 12px;
      height: 12px;
    }
  }

  @media (max-width: 900px) {
    .containerCarousel {
      img {
        height: 320px;
      }
    }
  }

  .boxAd {
    position: absolute;
    margin: 0 0 0rem 0rem;
    bottom: 0;
    padding: 15px;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.25) 60.84%, rgba(255, 255, 255, 0) 100%);
    
    z-index: 9;
    
    
    @media (max-width: 900px) {
      margin: 0 0 0rem 0rem;
    }
    @media (max-width: 450px) {
      margin: 0 0 0rem 0rem;
    }

    .box {
      padding: 0;
      display: flex;
      text-align: left;
      margin-bottom: 2rem;
      div {
        display: flex;
        padding-left: 3rem;
        color: black;
        @media (max-width: 450px) {
          padding-left: 2.5rem;
          
        }
        
        .imageAd {
          align-self: center;
          width: 6rem;
          height: 6rem;

          @media (max-width: 900px) {
            width: 4rem;
            height: 4rem;
          }
          @media (max-width: 450px) {
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }

      p {
        text-align: left;
        padding-left: 2rem;
        padding-top: 13px;
        display: block;
        
        font-weight: 600;
        font-size: 2rem;
        color: black;

        @media (max-width: 900px) {
         font-size : 1.25rem;
        }
        @media (max-width: 450px) {
          padding-top: 8px;
          padding-left: 1rem;
        }
      }
      span {
        text-align: left;
        font-size: 2rem;
        color: black;
        padding-left: 2rem;
        @media (max-width: 900px) {
          font-size : 1.25rem;
        }
        @media (max-width: 450px) {
          padding-left: 1rem;
        }
      }
    }
    }
`
