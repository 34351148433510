import ImageGallery from 'react-image-gallery';
import foto1 from "../../Assets/GaleriaImpExterna/1.jpg";
import foto2 from "../../Assets/GaleriaImpExterna/2.jpg";
import foto3 from "../../Assets/GaleriaImpExterna/3.jpg";
import foto4 from "../../Assets/GaleriaImpExterna/4.jpg";

import { Container } from "./styleGaleriaServicos";

export function ImpExternaCarousel({ fotosImpExterna }: any) {
  const images = [
    {
      original: foto1,
    },
    {
      original: foto2,
    },
    {
      original: foto3,
    },
    {
      original: foto4,
    },
  ];
  

  fotosImpExterna = images;

  return (
     <Container>
      <ImageGallery items={fotosImpExterna} />
      </Container>
  )};

