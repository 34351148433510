import ReactGA from 'react-ga';
import SendEmail from '../../Components/SendEmail';

function initizeAnalytics() {
  ReactGA.initialize("UA-191935382-1");
  ReactGA.pageview('/fale-conosco');
}

export function FaleConosco() {
  initizeAnalytics()

  return (
    <SendEmail />
  );
}
