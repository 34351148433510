import ReactGA from 'react-ga';
import { Container } from "./styleQuemSomos";
import quemsomos from '../../Assets/quemSomosBanner.jpg';

function initizeAnalytics() {
  ReactGA.initialize("UA-191935382-1");
  ReactGA.pageview('/quem-somos');
}

export function QuemSomos() {
  initizeAnalytics()
  return (
    <>
      <Container>
        <div className="header">
          <h1>Quem Somos</h1>
        </div>

        <div className="content">
          <div>
            <h2>
              Caixa D'Água Revestimentos
            </h2>
            <p>
            Fundada no ano de 1994, a Caixa D'Água Revestimentos LTDA oferece aos clientes mais de 20 anos de experiência no mercado, com profissionais treinados e capacitados para executar serviços especializados. 
            Trabalhamos com material de tecnologia exclusiva e com registro de propriedade, que torna seu serviço de revestimento diferenciado e de alta qualidade.
            </p>
            <p>
            A empresa é cadastrada na vigilância Sanitária sob nº 2561, sendo este cadastro renovado anualmente.
            </p>
            <h3>
            Segmentos:
            </h3>
            <li>
              <ul> - Condomínios;</ul>
              <ul> - Residências;</ul>
              <ul> - Indústrias;</ul>
              <ul> - Empresas.</ul>
            </li>
            <div className="spanSlogan">
              <p>Fale consoco, somos especialistas!</p>
            </div>
          </div>
          
          <div className="contentImage">
            <img src={quemsomos} alt="Quem Somos" />
          </div>
        </div>

      </Container>
    </>
  );
}
