import { Link } from 'react-router-dom';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Container } from './styles'
import img1 from '../../Assets/2.png'
import img2 from '../../Assets/GaleriaServicosExecutados/1.jpg'
import oneImg from '../../Assets/champion.svg'
import twoImg from '../../Assets/Group.svg'

export function Banner() {
  return (
    <Container>
      <div>
        <Carousel autoPlay infiniteLoop transitionTime={1500} interval={5000} >
          <div className="containerCarousel">
            <img alt="" src={img1} />
            <div className="boxAd">
              <div className="box">

                <Link to="/revestimentos">
                  <div>
                    <img className='imageAd' src={oneImg} alt="Caixa D'Água Revestimentos" />
                  </div>
                  <p>Tecnologia Exclusiva</p>
                  <span>Material de alta qualidade</span>
                </Link>

              </div>
            </div>
          </div>
          <div className="containerCarousel">

            <img alt="" src={img2} />
            <div className="boxAd">
              <div className="box">
                <Link to="/revestimentos">
                  <div>
                    <img className='imageAd' src={twoImg} alt="Caixa D'Água Revestimentos" />
                  </div>
                  <p>Sistema de Revestimento</p>
                  <span>PVC Flexível e Atóxico</span>
                </Link>

              </div>
            </div>
          </div>
        </Carousel>
      </div >
    </Container >
  )
}


