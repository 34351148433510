import ImageGallery from 'react-image-gallery';
import foto1 from "../../Assets/GaleriaServicosExecutados/1.jpg";
import foto2 from "../../Assets/GaleriaServicosExecutados/2.jpg";
import foto3 from "../../Assets/GaleriaServicosExecutados/3.jpg";
import foto4 from "../../Assets/GaleriaServicosExecutados/4.jpg";
import foto5 from "../../Assets/GaleriaServicosExecutados/5.jpg";
import foto7 from "../../Assets/GaleriaServicosExecutados/7.jpg";
import foto8 from "../../Assets/GaleriaServicosExecutados/8.jpg";
import foto9 from "../../Assets/GaleriaServicosExecutados/9.jpg";
import foto10 from "../../Assets/GaleriaServicosExecutados/10.jpg";
import foto11 from "../../Assets/GaleriaServicosExecutados/11.jpg";
import foto12 from "../../Assets/GaleriaServicosExecutados/12.jpg";
import foto13 from "../../Assets/GaleriaServicosExecutados/13.jpg";
import foto14 from "../../Assets/GaleriaServicosExecutados/14.jpg";
import foto15 from "../../Assets/GaleriaServicosExecutados/15.jpg";

import { Container } from "./styleGaleriaServicos";

export function ServicosExecutadosCarousel({ fotosServicosExecutados }: any) {
  const images = [
    {
      original: foto1,
      // thumbnail: foto1Thumbs,
    },
    {
      original: foto2,
    },
    {
      original: foto3,
    },
    {
      original: foto4,
    },
    {
      original: foto5,
    },
    {
      original: foto7,
    },
    {
      original: foto8,
    },
    {
      original: foto9,
    },
    {
      original: foto10,
    },
    {
      original: foto11,
    },
    {
      original: foto12,
    },
    {
      original: foto13,
    },
    {
      original: foto14,
    },
    {
      original: foto15,
    },
  ];
  

  fotosServicosExecutados = images;

  return (
    
     <Container>
      <ImageGallery items={fotosServicosExecutados} />
      </Container>
  )};

