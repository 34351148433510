import ImageGallery from 'react-image-gallery';
import foto1 from "../../Assets/GaleriaRejeitos/1.jpg";
import foto2 from "../../Assets/GaleriaRejeitos/2.jpg";
import foto3 from "../../Assets/GaleriaRejeitos/3.jpg";
import foto4 from "../../Assets/GaleriaRejeitos/4.jpg";

import { Container } from "./styleGaleriaServicos";

export function RejeitosCarousel({ fotosRejeitos }: any) {
  const images = [
    {
      original: foto1,
      // thumbnail: foto1Thumbs,
    },
    {
      original: foto2,
    },
    {
      original: foto3,
    },
    {
      original: foto4,
    },
  ];

  fotosRejeitos = images;

  return (
    
     <Container>
      <ImageGallery items={fotosRejeitos} />
      </Container>
  )};

