import Modal from 'react-modal';
import closeImg from '../../Assets/close.svg';
import { RevestimentoCarousel } from '../Galeria/galeriaRevestimentos';


Modal.setAppElement('#root');

interface NewTransactionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function RevestimentoModalFotos({ isOpen, onRequestClose}: NewTransactionModalProps) {

  return (
    <Modal 
    isOpen={isOpen} 
    onRequestClose={onRequestClose}
    overlayClassName="react-modal-overlay"
    className="react-modal-content"
  >
    <RevestimentoCarousel />
    <button 
      type="button" 
      onClick={onRequestClose} 
      className="react-modal-close">

      <img src={closeImg} alt="Fechar modal" />
    </button>
  </Modal>
  )
}