import backgroundHeader from '../../Assets/backgroundHeader.jpg'
import styled from 'styled-components';

export const Container = styled.main`
   padding-top: 7rem;

   .header {
    height: 8rem;
    margin-top: 8rem;
    background-image: url(${backgroundHeader});
    margin:auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;

    h1 {
      color: white;
    }
  }

  .content {
    margin-top: 2.5rem;
    margin-left: 3rem;
    margin-right: 2rem;
    margin-bottom: 3.5rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 3rem;
    text-align: justify;


    @media (max-width: 1450px) {
      display: block;
      margin: 2.5rem 4rem 3.5rem 4rem;

      .contentImage {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        align-content: center;

        img {
          width: 60%;
          height: 60%;
          
          }
      }


      h2 {
        font-size: 1.5rem;
        text-align: center;

      }

        p {
          font-size: 1rem;

          &:last-child{
            padding-bottom: 3rem;
          }
        }
      }

      h3 {
        padding-top: 2rem;
        padding-bottom: 1rem;
      }

      span {
       padding-top: 3rem;
       
       display: flex;
        font-size: 1.2rem;
       

        &:last-child{
          padding-top: .5rem;
          padding-bottom: 3rem;
        }
      }
    }

    
    p {
      
      & + p {
        padding-top: 1rem;
      }
    }

    img {
      max-width: 750px;
    }

    h2 {
      padding-bottom: 1.5rem;
    }

    h3 {
      padding-top: 2rem;
      padding-bottom: 1rem;
    }

    .spanSlogan {
      
      margin:auto;
      align-items: center;
      text-align: center;
      justify-content: center;
      align-content: center;
    
      p {
        padding-top: 3rem;
        text-align: center;  
        font-size: 1rem;
        font-weight: 600;

        &:last-child{
          padding-top: .5rem;
          
          
        }
      }
    }

    .contentCarousel {
      margin: 0;
    }
`

export const Revestimento = styled.div`
  background-color: #CFCFCF;
  max-width: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: center;
  display: flex;


  .content-left {
    height: auto;
    margin: 0rem 0rem;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    color: var(--text-title);
  
    .content-main {
      display: block;
      height: auto;
      text-align: center;
      margin-bottom: 8rem;
      
      h2 {
          color: black;
          font-size: 2.5rem;
          padding-top: 2rem;
          padding-bottom: 4rem;
          
        }
    }

    .content-header {
      display: block;
      height: auto;
      text-align: center;

      & + .content-header {
        margin-top: 6rem;
      }

        h2 {
          color: black;
          font-size: 2rem;
          padding-bottom: 1.5rem;
          
          
        }
    }

    div {
      max-width: 1100px;
      height: 25rem;
      align-items: center;
      text-align: center;
      justify-content: left;
      align-content: left;
      display: flex;
      
      
      img {
        width: 100rem;
        height: 30rem;
        
      }
      
      p {
        padding-left: 1rem;
        font-size: 1.8rem;
        color: black;
      }
    }
  }
`