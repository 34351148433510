import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #F0F2F5;
    --red:#E52E4D;
    --green: #33CC95;
    --blue:#5429CC;
    --blue-light:#6933FF;
    --text-title:#363F5F;
    --text-body:#969CB2;
    --shape:#FFFFFF;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 90%;   
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
    
    @media (max-width: 570px) {
      font-size: 75.5%;
    }
 }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;

    ::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(118, 188, 255, 0.3);
      border-radius: 10px;

    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(118, 188, 255, 0.3);
      background: #94d9ff;    
    }
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
  }

  li {
    list-style: none;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-modal-content {
    width: 100%;
    max-width: 900px;
    background: var(--background);
    padding: 1rem;
    position: relative;
    border-radius: 0.24rem;

  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;

    transition: filter 0.2s;
      &:hover {
        filter: brightness(0.6); 
      }
  }

`