import ImageGallery from 'react-image-gallery';
import foto1 from "../../Assets/GaleriaRevestimento/1.jpg";
import foto2 from "../../Assets/GaleriaRevestimento/2.jpg";
import foto3 from "../../Assets/GaleriaRevestimento/3.jpg";
import foto4 from "../../Assets/GaleriaRevestimento/4.jpg";
import foto5 from "../../Assets/GaleriaRevestimento/5.jpg";
import foto6 from "../../Assets/GaleriaRevestimento/6.jpg";
import foto7 from "../../Assets/GaleriaRevestimento/7.jpg";
import foto8 from "../../Assets/GaleriaRevestimento/8.jpg";
import foto9 from "../../Assets/GaleriaRevestimento/9.jpg";
import foto10 from "../../Assets/GaleriaRevestimento/10.jpg";

import { Container } from "./styleGaleriaServicos";

export function RevestimentoCarousel({ fotosRevestimento }: any) {
  const images = [
    {
      original: foto1,
      // thumbnail: foto1Thumbs,
    },
    {
      original: foto2,
    },
    {
      original: foto3,
    },
    {
      original: foto4,
    },
    {
      original: foto5,
    },
    {
      original: foto6,
    },
    {
      original: foto7,
    },
    {
      original: foto8,
    },
    {
      original: foto9,
    },
    {
      original: foto10,
    },
  
  ];
  

  fotosRevestimento = images;

  return (
    
     <Container>
      <ImageGallery items={fotosRevestimento} />
      </Container>
  )};

