import ReactGA from 'react-ga';
import { Container } from "./styleRevestimentos";
import img1 from '../../Assets/Revestimento/1.jpg';
import img2 from '../../Assets/Revestimento/2.jpg';
import img3 from '../../Assets/Revestimento/3.jpg';
import img4 from '../../Assets/Revestimento/4.jpg';
import img5 from '../../Assets/Revestimento/5.jpg';
import img6 from '../../Assets/Revestimento/6.jpg';
import img7 from '../../Assets/Revestimento/7.jpg';
import img8 from '../../Assets/Revestimento/8.jpg';
import img9 from '../../Assets/Revestimento/9.jpg';
import img10 from '../../Assets/Revestimento/10.jpg';
import img11 from '../../Assets/Revestimento/11.jpg';
import img12 from '../../Assets/Revestimento/12.jpg';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function initizeAnalytics() {
  ReactGA.initialize("UA-191935382-1");
  ReactGA.pageview('/revestimentos');
}

export function Revestimentos() {
  initizeAnalytics();
  return (
    <>
      <Container>
        <div className="header">
          <h1>Revestimento</h1>
        </div>

        <div className="content">
          <div> 
            <h3>
            Tecnologia EXCLUSIVA e COM REGISTRO DE PROPRIEDADE
            </h3>
            <p>
            Impermeabilização da caixa d’água com revestimento laminado de PVC flexível e ATÓXICO, que resulta em uma água limpa e saudável. Para confecção e instalação é utilizado material inoxidável e solda de alta frequência com tecnologia de alta qualidade. A umidade resultante do contato da água com a alvenaria desaparece!
            </p>
            <h3>
            Vantagens do Revestimento:
            </h3>
            <li>
              <ul> - Não descasca;</ul>
              <ul> - Não deforma;</ul>
              <ul> - Não solta resíduos;</ul>
              <ul> - Devido à sua flexibilidade, não necessita de suportes especiais para montagem;</ul>
              <ul> - Facilidade de transporte e manuseio. </ul>
              <ul> - Revestimento feito sob medida para sua caixa d’água;</ul>
              <ul> - Execução do serviço de revestimento em UM DIA; </ul>
            </li>
            <h4>
            - GARANTIA DE 08 (OITO) ANOS.
            </h4>

            <h3>
            MANUTENÇÃO:
            </h3>
            <p>
            Em caso de furos, rasgos ou danos ocorridos, a manta pode ser convenientemente reparada sem o comprometimento de sua utilização futura. 
            </p>
       
            <h3>
            APLICAÇÃO:
            </h3>
            <p>
            Impermeabilização em obras geotécnicas, construção civil e agronegócios.  Impermeabilização de esterqueiras, tratamento de esgotos, tanques para aquicultura, canais, área de lixiviação, caixas d’água, caixas de drenagem, reservatórios industriais e rurais, armazenamento de água, aterros sanitários, biodigestores, lagoas, campos de golfe, praia artificial, cisternas.
            </p>
            <div className="spanSlogan">
              <p>Já pensou qual a importância da água que você consome?</p>
              <p>Então não deixe para depois o que você consome hoje!!!</p>
            </div>
          </div>
          <div className="contentCarousel">
            <Carousel autoPlay infiniteLoop transitionTime={1000} interval={4000} >
              <div className="containerCarousel">
                <img alt="" src={img1} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img2} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img3} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img4} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img5} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img6} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img7} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img8} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img9} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img10} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img11} />
              </div>
              <div className="containerCarousel">
                <img alt="" src={img12} />
              </div>
            </Carousel>
            </div>
        </div>
      </Container>
      
    </>
  );
}
