import ImageGallery from 'react-image-gallery';
import foto1 from "../../Assets/GaleriaAguaFluvial/1.jpg";
import foto2 from "../../Assets/GaleriaAguaFluvial/2.jpg";
import foto3 from "../../Assets/GaleriaAguaFluvial/3.jpg";
import foto4 from "../../Assets/GaleriaAguaFluvial/4.jpg";
import foto5 from "../../Assets/GaleriaAguaFluvial/5.jpg";
import foto6 from "../../Assets/GaleriaAguaFluvial/6.jpg";

import { Container } from "./styleGaleriaServicos";

export function AguaFluvialCarousel({ fotosAguaFluvial }: any) {
  const images = [
    {
      original: foto1,
    },
    {
      original: foto2,
    },
    {
      original: foto3,
    },
    {
      original: foto4,
    },
    {
      original: foto5,
    },
    {
      original: foto6,
    },
  ];
  
  fotosAguaFluvial = images;

  return (
     <Container>
      <ImageGallery items={fotosAguaFluvial} />
      </Container>
  )};

